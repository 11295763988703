<script>
  export let display = false;
</script>

<!-- style={`display:${display ? "block" : "none"};`} -->
<svg
	class={`${$$props.class}`}
  xmlns="http://www.w3.org/2000/svg"
  id="Layer_1"
  viewBox="0 0 15.73071 20"
  width="15.73071"
  height="20">
  <polygon
    points=".76746 5.05775 9.52775 10.11551 9.52775 0 .76746 5.05775"
    fill="#ed2024" />
  <path
    d="M14.23291,17.00342h-1.1391v-.43774c0-.70892-.57471-1.28363-1.28363-1.28363h-2.28253v-5.16663l-1.72455-.99567v6.16229H3.91949c-.70831,0-1.28259,.57422-1.28259,1.28259v.43878H1.4967c-.8266,0-1.4967,.6701-1.4967,1.4967v1.49988H15.73071v-1.49884c0-.82715-.67059-1.49774-1.4978-1.49774Z"
    fill="#231f20" />
</svg>

<!-- <svg
	style={`display:${display ? "block" : "none"};`}
	version="1.1"
	x="0px"
	y="0px"
	width="20px"
	height="20px"
	viewBox="0 0 76 76"
	enable-background="new 0 0 76 76"
	xmlns="http://www.w3.org/2000/svg"
>
	<g
		style=""
		transform="matrix(1.344856, 0, 0, 1.344856, -12.180226, -19.418886)"
	>
		<g>
			<polygon
				points="35.243 63.759 35.243 35.063 41.152 35.063 41.152 63.759 35.243 63.759"
				style=""
			/>
			<polygon
				fill="#FF0000"
				points="41.11 16.087 10.681 28.57 41.172 41.053"
				style=""
				transform="matrix(1, 0.001623, -0.001623, 1, 0.046403, -0.042041)"
			/>
			<rect
				x="19.612"
				y="47.926"
				width="34.482"
				height="5.977"
				style=""
				transform="matrix(1, 0, -0.010915, 1, 1.899863, 15.734144)"
				rx="3.52"
				ry="3.52"
			/>
		</g>
	</g>
</svg> -->
